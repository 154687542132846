<template>
<div
  class="wrapper"
  :style="{position:'fixed',top:position.y +'px',left:position.x+'px'}">
  <div v-if="!mocklist.length" class="empty">无搜索结果</div>
  <div
    v-for="(item,i) in mocklist"
    :key="item.username"
    class="item"
    :class="{'active': i === index}"
    ref="usersref"
    @click="clickat($event,item)"
    @mouseenter="hoverat(i)"
  >
    <div class="name">{{item.name}}</div>
  </div>
</div>
</template>

<script>
const mockdata = [
  { name: '祁帅', username: 'SFC0101' },
  { name: '李静', username: 'STGF2121' },
  { name: '曾志禄', username: 'SFC1577' },
  { name: '陈彦有', username: 'SFC2541' }
]
export default {
  name: 'atdialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    position: {
      type: Object,
      default: function() {
        return {}
      }
    },
    querystring: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      users: [],
      index: -1,
      mocklist: mockdata
    }
  },
  watch: {
    querystring(val) {
      val ? this.mocklist = mockdata.filter(({ name }) => name.startsWith(val)) : this.mocklist = mockdata.slice(0)
    }
  },
  mounted() {
    document.addEventListener('keyup', this.keydownhandler)
  },
  destroyed() {
    document.removeEventListener('keyup', this.keydownhandler)
  },
  methods: {
    keydownhandler(e) {
      if (e.code === 'Escape') {
        this.$emit('onhide')
        return
      }
      // 键盘按下 => ↓
      if (e.code === 'ArrowDown') {
        if (this.index >= this.mocklist.length - 1) {
          this.index = 0
        } else {
          this.index = this.index + 1
        }
      }
      // 键盘按下 => ↑
      if (e.code === 'ArrowUp') {
        if (this.index <= 0) {
          this.index = this.mocklist.length - 1
        } else {
          this.index = this.index - 1
        }
      }
      // 键盘按下 => 回车
      if (e.code === 'Enter') {
        if (this.mocklist.length) {
          const user = {
            name: this.mocklist[this.index].name,
            username: this.mocklist[this.index].username
          }
          this.$emit('onpickuser', user)
          this.index = -1
        }
      }
    },
    clickat(e, item) {
      const user = {
        name: item.name,
        username: item.username
      }
      this.$emit('onpickuser', user)
      this.index = -1
    },
    hoverat(index) {
      this.index = index
    }
  }
}
</script>

<style scoped lang="scss">
  .wrapper {
    width: 238px;
    border: 1px solid #e4e7ed;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 #000;
    box-sizing: border-box;
    padding: 6px 0;
    max-height: 250px;
    overflow-y: auto;
  }
  .empty{
    font-size: 14px;
    padding: 0 20px;
    color: #999;
  }
  .item {
    font-size: 14px;
    padding: 0 20px;
    line-height: 34px;
    cursor: pointer;
    color: #606266;
    &.active {
      background: #f5f7fa;
      color: #3370ff;
      .id {
        color: #3370ff;
      }
    }
    &:first-child {
      border-radius: 5px 5px 0 0;
    }
    &:last-child {
      border-radius: 0 0 5px 5px;
    }
    .id {
      font-size: 12px;
      color: rgb(83, 81, 81);
    }
  }
</style>
