<template>
  <div class="app-container">
    <div class="content">
      <!--文本框-->
      <div
        class="editor"
        id="customInput"
        ref="divref"
        contenteditable
        @keyup="handkekeyup"
        @keydown="handlekeydown"
      ></div>
      <!--选项-->
      <atdialog
        v-if="showdialog"
        :visible="showdialog"
        :position="position"
        :querystring="querystring"
        @onpickuser="handlepickuser"
        @onhide="handlehide"
        @onshow="handleshow"
      ></atdialog>
      <el-button @click="submit">提交</el-button>
    </div>
  </div>
</template>
<script>
import atdialog from './atdialog.vue'
export default {
  name: 'sandbox',
  components: { atdialog },
  data() {
    return {
      node: '', // 获取到节点
      user: '', // 选中项的内容
      endIndex: '', // 光标最后停留位置
      querystring: '', // 搜索值
      showdialog: false, // 是否显示弹窗
      position: {
        x: 0,
        y: 0
      }, // 弹窗显示位置
      nowUser: {
        name: '祁帅',
        username: 'SFC0101'
      } // 如果@当前登录人，则展示样式不一样
    }
  },
  methods: {
    // 获取光标位置
    getcursorindex() {
      const selection = window.getSelection()
      return selection.focusOffset // 选择开始处 focusnode 的偏移量
    },
    // 获取节点
    getrangenode() {
      const selection = window.getSelection()
      return selection.focusNode // 选择的结束节点
    },
    // 弹窗出现的位置
    getrangerect() {
      const selection = window.getSelection()
      const range = selection.getRangeAt(0) // 是用于管理选择范围的通用对象
      const rect = range.getClientRects()[0] // 择一些文本并将获得所选文本的范围
      const line_height = 30
      console.log('rect', rect)
      if (rect) {
        return {
          x: rect.x,
          y: rect.y + line_height
        }
      } else {
        return {
          x: 0,
          y: 0
        }
      }
    },
    // 是否展示 @
    showat() {
      const node = this.getrangenode()
      if (!node || node.nodetype !== node.text_node) return false
      const content = node.textContent || ''
      const regx = /@([^@\s]*)$/
      const match = regx.exec(content.slice(0, this.getcursorindex()))
      return match && match.length === 2
    },
    // 获取 @ 用户
    getatuser() {
      const content = this.getrangenode().textContent || ''
      const regx = /@([^@\s]*)$/ // ()代表正则子表达式，match[1] 即为子表达式匹配到的内容
      const match = regx.exec(content.slice(0, this.getcursorindex()))
      if (match && match.length === 2) {
        console.log('match', match[1])
        return match[1]
      }
      return undefined
    },
    // 创建标签
    createatbutton(user) {
      console.log(user, '000')
      const btn = document.createElement('span')
      // btn.style.display = 'inline-block'
      // btn.dataset.user = JSON.stringify(user)
      if (user.username === this.nowUser.username) {
        btn.className = 'at-button'
      } else {
        btn.className = 'mt-button'
      }
      btn.contentEditable = 'false'
      btn.textContent = `@${user.name}`
      const wrapper = document.createElement('span')
      // wrapper.style.display = 'inline-block'
      wrapper.className = 'ot-button'
      wrapper.contentEditable = 'false'
      const spaceelem = document.createElement('span')
      spaceelem.style.whiteSpace = 'pre'
      spaceelem.textContent = '\u200b'
      spaceelem.contentEditable = 'false'
      const clonedspaceelem = spaceelem.cloneNode(true)
      wrapper.appendChild(spaceelem)
      wrapper.appendChild(btn)
      wrapper.appendChild(clonedspaceelem)
      return wrapper
    },
    replacestring(raw, replacer) {
      return raw.replace(/@([^@\s]*)$/, replacer)
    },
    // 插入@标签
    replaceatuser(user) {
      const node = this.node
      if (node && user) {
        const content = node.textContent || ''
        const endindex = this.endIndex
        const preslice = this.replacestring(content.slice(0, endindex), '')
        const restslice = content.slice(endindex)
        const parentnode = node.parentNode
        const nextnode = node.nextSibling
        const previoustextnode = new Text(preslice)
        const nexttextnode = new Text('\u200b' + restslice) // 添加 0 宽字符
        const atbutton = this.createatbutton(user)
        parentnode.removeChild(node)
        // 插在文本框中
        if (nextnode) {
          parentnode.insertBefore(previoustextnode, nextnode)
          parentnode.insertBefore(atbutton, nextnode)
          parentnode.insertBefore(nexttextnode, nextnode)
        } else {
          parentnode.appendChild(previoustextnode)
          parentnode.appendChild(atbutton)
          parentnode.appendChild(nexttextnode)
        }
        // 重置光标的位置
        const range = new Range()
        const selection = window.getSelection()
        range.setStart(nexttextnode, 0)
        range.setEnd(nexttextnode, 0)
        selection.removeAllRanges()
        selection.addRange(range)
      }
    },
    // 键盘抬起事件
    handkekeyup() {
      if (this.showat()) {
        const node = this.getrangenode()
        const endIndex = this.getcursorindex()
        this.node = node
        this.endIndex = endIndex
        this.position = this.getrangerect()
        this.querystring = this.getatuser() || ''
        this.showdialog = true
        console.log('node', this.node)
        console.log('querystring', this.querystring)
      } else {
        this.showdialog = false
      }
    },
    // 键盘按下事件
    handlekeydown(e) {
      if (this.showdialog) {
        if (
          e.code === 'ArrowUp' ||
          e.code === 'ArrowDown' ||
          e.code === 'Enter'
        ) {
          e.preventdefault()
        }
      }
      console.log('e', e)
    },
    // 插入标签后隐藏选择框
    handlepickuser(user) {
      this.replaceatuser(user)
      this.user = user
      this.showdialog = false
    },
    // 隐藏选择框
    handlehide() {
      this.showdialog = false
    },
    // 显示选择框
    handleshow() {
      this.showdialog = true
    },
    submit() {
      console.log(this.$refs.divref.innerText)
      console.log(this.$refs.divref.innerHTML)
      // 取出所有@人信息 数据库单独字段存储人名 祁帅,李静,曾志禄，回显时通过匹配content内容，将@祁帅、@李静、@曾志禄，用样式标签包起来。
      // 编辑时，按照如下格式回填输入框
      // const html = `<span class="ot-button" contenteditable="false" style="display: inline-block;"><span contenteditable="false" style="white-space: pre;">​</span>
      // <span data-user="{&quot;name&quot;:&quot;曾志禄&quot;,&quot;username&quot;:&quot;SFC1577&quot;}" class="at-button" contenteditable="false">@曾志禄</span>
      // <span contenteditable="false" style="white-space: pre;">​</span></span>阿发二期`
      // this.$refs.divref.innerHTML = html
      // const parentNode = this.$refs.divref
      // const users = parentNode.children('.ot-button')
      const users = document.querySelectorAll('#customInput .ot-button')
      const userArr = []
      for (let i = 0; i < users.length; i++) {
        console.log(users[i].innerText)
        userArr.push(users[i].innerText)
      }
      const comment = document.querySelector('#customInput').innerText
      console.log(comment)
      const params = {
        comment: comment,
        remind: userArr.join(',')
      }
      console.log('params', params)
    }
  }
}
</script>
<style lang="scss">
.content {
  font-family: sans-serif;
  h1 {
    text-align: center;
  }
}
.editor {
  margin: 0 auto;
  width: 600px;
  height: 150px;
  background: #fff;
  border: 1px solid #3370ff;
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  overflow: auto;
  line-height: 22px;
  &:focus {
    outline: none;
  }
}
.ot-button {
  padding: 0 5px;
  display: inline-block;
  .at-button {
    font-size: 14px;
    display: inline-block;
    padding: 0 4px !important;
    background: #3370ff;
    color: #ffffff;
    margin: 0 0.1px;
    border-radius: 999px;
  }
  .mt-button {
    font-size: 14px;
    display: inline-block;
    padding: 0 4px !important;
    // background: #3370ff;
    color: #3370ff;
    margin: 0 0.1px;
    // border-radius: 999px;
  }
}
</style>
